import React from 'react';
import Grid  from  '@mui/material/Grid';
import Paper  from  '@mui/material/Paper';

import useAxios      from '../../../hooks/useAxios';
import { useParams } from 'react-router-dom';
import { Alert, Button, Typography } from '@mui/material';
import Form from '../../../components/Comentarios/Form';
import {formatarFechaLaravel,formatarHoraAMPMLaravel} from '../../../helpers/functions';

const BACKEND = window.location.hostname === 'localhost' ? process.env.REACT_APP_BACKEND : process.env.REACT_APP_BACKENDREMOTE;



const Edit = () => {
  const [data,setData]              =   React.useState({file:"",comment:""})
  const axios                       =   useAxios();
  const params                      =   useParams();

  const getInit=()=>{
    setData({})
    axios.getData({},"/dashboard/pqr/"+params["*"]).then((response)=>{
      if (response&&response.data) {
        let data_ = []
        setData({...response.data})
      }
    })
  }

  React.useEffect(getInit,[])

  const handleClick=()=>{
    axios.postData({},"/dashboard/pqr/"+params["*"]+"/close").then((response)=>{
      if (response&&response.data) {
        let data_ = []
        setData({...response.data})
      }
    })
  }

  const colorBG = (data.token==="Recibido sistema PQR")?"#ff3b00":(data.token==="En proceso de Gestión")?"#81c784":(data.token==="Respuesta")?"orange":(data.token==="Respuesta")?"green":"#f2f2f2";
  const color   = (data.token==="Recibido sistema PQR")?"#fff":(data.token==="En proceso de Gestión")?"#fff":(data.token==="Respuesta")?"orange":(data.token==="Respuesta")?"green":"#f2f2f2";
  
  //const commentWithoutPTags = row?.comment.replace(/<p>.*?<\/p>/g, '');

  return  <Grid container spacing={2}>
            <Grid item xs={12} md={4}>              
              <Paper sx={{p:2, position:"relative"}} >
                <Grid sx={{position:"absolute", right:0, p:1, background:colorBG, fontSize:12,color:color}}>
                  {
                    data.token
                  }
                </Grid>
                {
                  data.token!=='Cerrado'&&<Button variant="contained" onClick={handleClick}>Cerrar</Button>
                }                
                {
                  data.token==='Cerrado'&&<Alert>Este caso ha sido cerrado</Alert>
                }                
                <Typography variant='div'>
                  <div dangerouslySetInnerHTML={{ __html: data && data.comment && data.comment.replace && data.comment.replace(/<p>[\s\S]*?<\/p>/g, '') }} />                  
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}> 
                {
                  data.token!=='Cerrado'&&<Grid>
                                              <Form data={{id:params["*"]}} getInit={getInit}/>                    
                                          </Grid>        
                }

                <Paper sx={{p:2,mb:2}}>
                  <Typography variant='div' component={"h6"}>
                    {data?.longitude} { formatarFechaLaravel(data?.updated_at) } - { formatarHoraAMPMLaravel(data?.updated_at) } 
                  </Typography>                              
                  <Typography variant='div'>
                    <div dangerouslySetInnerHTML={{ __html: data?.file }} />                                
                  </Typography>                              
                </Paper>
                
                {
                  data&&
                  data.replys&&
                  data.replys.map&&
                  data.replys.map((row,key)=>{
                    
                    return  <Paper sx={{p:2,mb:2}} key={key}>
                              <Typography variant='div' component={"h6"}>
                                {row?.users?.name} { formatarFechaLaravel(row?.updated_at) } - { formatarHoraAMPMLaravel(row?.updated_at) } 
                              </Typography>                              
                              <Typography variant='div'>
                                <div dangerouslySetInnerHTML={{ __html: row?.comment }} />                                
                              </Typography>                              
                            </Paper>
                  })
                }              
            </Grid>            
          </Grid>
};

export default Edit;
