import { useContext } from 'react';
import Container    from '../../components/Theme/Auth';
import Input        from '../../components/Input';
import Submit       from '../../components/Button/Loading';
import useFormData  from '../../hooks/useFormData';
import useAxios     from '../../hooks/useAxios';
import useAsyncStorage     from '../../hooks/useAsyncStorage';
import StateContext from '../../helpers/ContextState';
import Button       from '@mui/material/Button';
import { NavLink }  from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";

const Login=()=>{
  const navigate                =   useNavigate();
  const location                =   useLocation();
  const context                 =   useContext(StateContext);
  const { formData, onChange }  =   useFormData();
  const axios                   =   useAxios();
  const userStorage             =   useAsyncStorage("user");

  const onSubmit=(e)=>{
    e.preventDefault()
    axios.postData(formData,"/login").then((response)=>{
      if (  response  &&
            response.data &&
            response.code &&
            response.code==="SUCCESS"
          ) {

        let permissions = {}
        if (response.data&&response.data.permissions) {
          response.data.permissions.map((row,key)=>{
            const split = row.name.split("_")
            if (!permissions[split[0]]) {
              permissions[split[0]] = []
            }
            return permissions[split[0]].push(split[1])
          })
        }


        const setStorageAndState  =  {
                                        access_token:response.data.access_token,
                                        ...response.data.user,
                                        roles:response.data.roles,
                                        permissions:permissions,
                                      };
        /*vamos a setear el estado en App.js para que esté disponible global*/
        context.setUser(setStorageAndState)
        /*Guardo en el storage también*/
        userStorage.setData(setStorageAndState).then(resp=>{
          context.setOpenMessage("Inicio de sesión, será redirigido a tu cuenta...")
          if (location.search==='?redirect=true' && window.history.length===0) {
            return navigate("/");
          }else {
            return navigate("/dashboard");
          }
        })
      }
    })
  }

  return  <Container onSubmit={onSubmit}>
            <Input sx={{mb:2}} required onChange={onChange} size="small" fullWidth name="email" type="email" label="Correo electrónico"/>
            <Input sx={{mb:2}} required onChange={onChange} size="small" fullWidth name="password" type="password" label="Password"/>
            <Submit fullWidth type="submit" label="Ingresar"/>
            <Button to="/auth/forgotPassword" component={NavLink}>Recuperar contraseña</Button>
          </Container>
}
export default Login
