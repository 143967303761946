/*set components*/
import Dashboard  from './Dashboard';
import Pqr  from './Pqr';

/*icons*/
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';



/*set icons*/
import HomeIcon from '@mui/icons-material/Home';

export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Dashboard,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Home",
    path: "home",
    component: Dashboard,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "PQRs",
    url: "pqr",
    path: "pqr/*",
    component: Pqr,
    menu: false,
    private: true,
    icon: <ConfirmationNumberIcon />,
  },  
];
