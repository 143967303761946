export function formatarHoraAMPMLaravel(fechaLaravel) {
    // Crear un objeto de fecha a partir de la cadena de fecha de Laravel
    const fecha = new Date(fechaLaravel);
  
    // Obtener los componentes de la hora
    let horas = fecha.getHours();
    let minutos = fecha.getMinutes();
    let amPM = horas >= 12 ? 'PM' : 'AM';
  
    // Convertir a formato de 12 horas
    horas = horas % 12 || 12; // Ajustar a 12 en lugar de 0 para la medianoche
    minutos = minutos.toString().padStart(2, '0'); // Agregar ceros iniciales si es necesario
  
    // Construir la cadena de hora en el formato HH:mm AM/PM
    const horaFormateada = `${horas}:${minutos} ${amPM}`;
  
    return horaFormateada;
  }
  
  export function formatarFechaLaravel(fechaLaravel) {
    // Crear un objeto de fecha a partir de la cadena de fecha de Laravel
    const fecha = new Date(fechaLaravel);
  
    // Obtener los componentes de la fecha
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Los meses en JavaScript son 0-indexados
    const anio = fecha.getFullYear().toString().slice(-2);
  
    // Construir la cadena de fecha en el formato DD/MM/YY
    const fechaFormateada = `${dia}/${mes}/${anio}`;
  
    return fechaFormateada;
  }