import  React from  'react';
import  Grid  from  '@mui/material/Grid';
const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref}>
              {props.children}
            </Grid>
});


export default Container
