import {useEffect, useState} from 'react';
import Grid  from  '@mui/material/Grid';
import { useLocation, NavLink } from "react-router-dom";
import { Button, Card, CardContent, Typography } from '@mui/material';
import Container    from '../../components/Theme/Dashboard';
import useAxios      from '../../hooks/useAxios';
import useFormData  from '../../hooks/useFormData';
import TablePaginationDemo from '@mui/material/Pagination';
import Search from '../../components/Table/Search';
import { useNavigate } from 'react-router-dom';
import {formatarFechaLaravel,formatarHoraAMPMLaravel} from '../../helpers/functions';

let getInit         =   null;
let filter          =   "";
const queryString   =   window.location.search;
const urlParams     =   new URLSearchParams(queryString);
let current         =   (parseInt(urlParams.get('page')))?parseInt(urlParams.get('page')):1

const Web=()=>{
  const navigate                        =   useNavigate();
  const axios                           =   useAxios();
  const { formData, setFormData }       =   useFormData();
  const [ status, setStatus ]           =   useState({});
  const [ select, setSelect ]           =   useState(false);
  const [ totalPages, setTotalPages]    =   useState(0);
  const location                        =   useLocation();

  getInit=()=>{
    const endpoint  =   window.location.pathname+location.search

    if (filter || filter==='') {
      setTotalPages(0)
    }

    axios.getData({},endpoint).then((response)=>{
      if (response&&response.data) {
        let status_={}
        setFormData(response.data)  
        if( response.data&&
            response.data.data&&
            response.data.data.map){
          
          response.data.data.map((row)=>{
            if(!status_[row.token]){
              return status_[row.token]  = 1;
            }else{
              return status_[row.token]  += 1;
            }            
          })            
        }     
        setStatus(status_)         
      }
      if (response&&response.data && response.data.links) {
        const calcular    =   Math.ceil(response.data.total / parseInt(response.data.per_page))
        setTotalPages(calcular)
      }
    })
  }

  const onChange=(event,page)=>{
    //console.log(subFixed);
    let new_url_set
    location.search="?page="+page
    navigate(location.pathname+"?page="+page)    
    getInit(true,new_url_set)
  }

  useEffect(()=>{
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  },[])

  const handleDonwload=()=>{
    const endpoint  =   window.location.pathname+"?excel=true"
    axios.getData({},endpoint).then((response)=>{
      if(response&&response.data){
        //console.log(response.data)
        document.location.href=response.data
      }
      //console.log(response,endpoint)
    })
    
  }

  return  <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TablePaginationDemo  defaultPage={current}
                                        onChange={onChange}
                                        count={totalPages}
                                        shape="rounded"
                                        color="secondary"
                                        siblingCount={1}
                                        boundaryCount={1}/>                  
                </Grid>
                <Grid item xs={12} md={8}>
                  {
                    Object.entries(status).map((row,key)=>{
                      return  <Button variant={select&&select===row[0]?'contained':"outlined"} key={key} sx={{mr:1}} onClick={()=>setSelect(row[0])}>
                                {row[0]}({row[1]})
                              </Button>
                    })
                  }
                  {
                    select&&(
                      <Button color="secondary" variant='contained' sx={{mr:1}} onClick={()=>setSelect(false)}>
                           Todos     
                      </Button>
                    )
                  }
                  <Button color="secondary" variant='contained' sx={{mr:1}} onClick={handleDonwload}>
                    Descargar
                  </Button>
                </Grid>                
                {
                  formData&&formData.data&&formData.data.map&&formData.data.map((row,key)=>{
                    const colorBG = (row.token==="Recibido sistema PQR")?"#ff3b00":(row.token==="En proceso de Gestión")?"#81c784":(row.token==="Respuesta")?"orange":(row.token==="Cerrado")?"#81c784":"#f2f2f2";
                    const color   = (row.token==="Recibido sistema PQR")?"#fff":(row.token==="En proceso de Gestión")?"#fff":(row.token==="Respuesta")?"orange":(row.token==="Cerrado")?"#fff":"#f2f2f2";

                    if(!select || (select&&select===row.token)){
                      return    <Grid item xs={12} md={3} key={key} >
                                  <Grid component={NavLink} to={"/dashboard/pqr/"+row.id} sx={{textDecoration:"none"}}>                                
                                    <Card variant="cardHome" sx={{position:"relative",background:parseInt(row.mensajes_nuevos)>0?"#ddd":""}} >
                                      <Grid sx={{position:"absolute", right:0, p:1, background:colorBG, fontSize:12,color:color}}>
                                        {
                                          row.token
                                        }
                                      </Grid>
                                      <CardContent align="center">
                                        <Typography variant="h3" component="div">
                                          {row.id}
                                        </Typography>
                                        <Typography variant="body2">
                                          {
                                            row.subject
                                          }
                                        </Typography>
                                        <Typography component="div" sx={{fontSize:11}}>
                                            <b>{ formatarFechaLaravel(row.updated_at) } { formatarHoraAMPMLaravel(row.updated_at) }                                      </b>
                                        </Typography>
                                        {
                                          parseInt(row.mensajes_nuevos)>0&&(
                                            <Typography component="div" sx={{fontSize:16}}>
                                                <b>
                                                  {
                                                    parseInt(row.mensajes_nuevos)                                                  
                                                  } Mensajes nuevos
                                                </b>
                                            </Typography>
                                          )
                                        }                                       
                                        
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Grid> 
                    }

                    return false;
                    
                  })
                }

                                
              </Grid>
          </Container>
}
export default Web

