/*set components*/
import Home  from './Home';
import Pqr  from './Pqr';
/*set icons*/
import HomeIcon from '@mui/icons-material/Home';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
  {
    name: "Home",
    path: "/prq",
    component: Pqr,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
];
