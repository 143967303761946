import React, { useState, useRef, useEffect } from 'react';
import { Button, List, ListItem, ListItemText } from '@mui/material';
import Grid from '@mui/material/Grid';
import './index.css';

import VoicePlayer from './VoicePlayer';
import useAxios      from '../../hooks/useAxios';

const document_location_href          =   '/comments';//document.location.pathname;

let getInit=false

const ItemMessage=({comment})=>{
  return  <Grid>
            {comment.mensaje==='Nota de voz'&&comment.attachment&&(<VoicePlayer audioSrc={comment.attachment}/>)}
            {comment.mensaje}
          </Grid>
}

const Comments = (props) => {
  const endpoint                      =   document_location_href+"?id="+(props&&props.subFixed?props.subFixed:"none_")+(props&&props.data&&props.data.id)

  const axios                         =   useAxios();
  const [isLoading, setIsLoading]     =   useState(false);
  const [comments, setComments]       =   useState([]);
  const [newComment, setNewComment]   =   useState('');
  const commentInputRef               =   useRef(null);
  const chatItemsRef                  =   useRef(null); // Referencia al contenedor de los comentarios

  getInit=()=>{
    axios.getData({},endpoint).then((response)=>{
      if (response&&response.data) {
        setIsLoading(false)
        setComments(response.data)
      }
    })
  }

  useEffect(() => {
    setIsLoading(true)
    getInit()
  }, []);

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleCommentSubmit = (event) => {
    event.preventDefault();
    //setComments([...comments, newComment]);
    handleSubmit(newComment,false)
    setNewComment('');
    commentInputRef.current.value = '';
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleCommentSubmit(event);
    }
  };

  useEffect(() => {
    // Mover el scroll hacia abajo cuando llegue un nuevo mensaje
    chatItemsRef.current.scrollTop = chatItemsRef.current.scrollHeight;
  }, [comments]);

  const handleSubmit=(newComment,attachment)=>{

    setIsLoading(true)
    const formData  = {
      modulo_token:endpoint,
      mensaje:newComment,
      attachment:attachment
    }

    //const endpoint2  = 'api/'+process.env.REACT_APP_BACKEND_VERSION+'/comments'

    axios.postDataAttachment(formData,endpoint).then((response)=>{
      if (response&&response.data) {
        setComments([...comments, response.data]);
        setIsLoading(false)
        //console.log(response.data);
      }
    })

  }

  return (
    <Grid sx={{ p: 4 }} className="chat-content">
      <Grid className="chat-main">
        <Grid className="chat-items" ref={chatItemsRef}>
          <List>
            {comments.map((comment, index) => (
              <ListItem key={index} className="comment-item">
                <ListItemText secondary={comment.autor.name + " " +(comment.fecha||"")} primary={<ItemMessage comment={comment}/>} className="comment-text" />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid className="chat-textarea">
          <textarea
            value={newComment}
            onChange={handleCommentChange}
            onKeyDown={handleKeyDown}
            placeholder="Escribe un mensaje..."
            ref={commentInputRef}
          ></textarea>
          <Button  disabled={isLoading||newComment===''} variant="contained" type="submit" color="primary" onClick={handleCommentSubmit}>
            Comentar
          </Button>

        </Grid>
      </Grid>
    </Grid>
  );
};

export default Comments;
