import React from 'react';
import { Grid, Typography, Paper , Alert, Button} from '@mui/material';
import useAxios      from '../../hooks/useAxios';
import { useParams } from 'react-router-dom';
import Form from '../../components/Comentarios/Form';
import {formatarFechaLaravel,formatarHoraAMPMLaravel} from '../../helpers/functions';
import Container    from '../../components/Theme/Container';
import  logo  from  '../../assets/png/logo192.png';
import  Avatar  from  '../../components/Avatar';

const Home=()=>{
    
    const [data,setData]              =   React.useState({})
    const axios                       =   useAxios();
    const params                      =   useParams();

    const queryString   =   window.location.search;
  
    const getInit=()=>{
      setData({})
      axios.getData({},"/pqr/commentsClientOpen"+queryString).then((response)=>{
        if (response&&response.data) {
          setData({...response.data})
        }
      })
    }
  
    React.useEffect(getInit,[])

    const colorBG = (data.token==="Recibido sistema PQR")?"#ff3b00":(data.token==="En proceso de Gestión")?"#81c784":(data.token==="Respuesta")?"orange":(data.token==="Respuesta")?"green":"#f2f2f2";
    const color   = (data.token==="Recibido sistema PQR")?"#fff":(data.token==="En proceso de Gestión")?"#fff":(data.token==="Respuesta")?"orange":(data.token==="Respuesta")?"green":"#f2f2f2";
    

    const handleClose=()=>{
      axios.postData({},"/pqr/close/byUser"+queryString).then((response)=>{
        if (response&&response.data) {
          setData({...response.data})
        }
      })
    }

    return  <Container>
              <Grid container spacing={2} justifyContent={"center"} sx={{background:"#f2f2f2", minHeight:"100vh"}}>
                <Grid item xs={12} md={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} align="center">
                      <Grid align="center">
                          <Avatar sx={{width:250,height:250,border:"solid 3px #fff", mt:2}} title="Programandoweb" alt="" src={logo}/>
                        </Grid>
                    </Grid>
                    {
                      /**
                       * 
                       *  <Grid item xs={12} align="center">
                            <Grid align="center">
                                <Button variant="contained" onClick={handleClose}>
                                  Cerrar y Aceptar PQR
                                </Button>
                              </Grid>
                          </Grid>
                       */
                    }
                    <Grid item xs={12} md={4}>              
                      <Paper sx={{p:2, position:"relative"}} >
                        <Grid sx={{position:"absolute", top:0, right:0, p:1, background:colorBG, fontSize:12.5,color:color}}>
                          {
                            data.token
                          }
                        </Grid>
                        {
                          data.token==='Cerrado'&&<Alert>Este caso ha sido cerrado</Alert>
                        }                
                        <Typography variant='div'>
                          <div dangerouslySetInnerHTML={{ __html: data && 
                                                                  data.comment && 
                                                                  data.comment.replace && 
                                                                  data.comment.replace(/<p>[\s\S]*?<\/p>/g, '') }} />                  
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={8}> 
                      {
                        data.token==='En proceso de Gestión'&&<Grid>
                                                    <Form data={{id:params["*"]}} url={"/pqr/insert_comments_open"+queryString} getInit={getInit}/>                    
                                                </Grid>        
                      }

                      <Paper sx={{p:2,mb:2}}>
                        <Typography variant='div' component={"h6"}>
                          {data?.longitude} { formatarFechaLaravel(data?.updated_at) } - { formatarHoraAMPMLaravel(data?.updated_at) } 
                        </Typography>                              
                        <Typography variant='div'>
                          <div dangerouslySetInnerHTML={{ __html: data?.file }} />                                
                        </Typography>                              
                      </Paper>
                    
                      {
                        data&&
                        data.replys&&
                        data.replys.map&&
                        data.replys.map((row,key)=>{
                          return  <Paper sx={{p:2,mb:2}} key={key}>
                                    <Typography variant='div' component={"h6"}>
                                      {row?.users?.name} { formatarFechaLaravel(row?.updated_at) } - { formatarHoraAMPMLaravel(row?.updated_at) } 
                                    </Typography>                              
                                    <Typography variant='div'>
                                      <div dangerouslySetInnerHTML={{ __html: row?.comment }} />                                
                                    </Typography>                              
                                  </Paper>
                        })
                      }              
                    </Grid>
                    {
                      /**
                       * 
                       *  <Grid item xs={12} align="center">
                            <Grid align="center">
                                <Button variant="contained" onClick={handleClose}>
                                  Cerrar y Aceptar PQR
                                </Button>
                              </Grid>
                          </Grid>
                       * 
                       */
                    }
                    
                  </Grid>
                </Grid>
              </Grid>
          </Container>
}
export default Home