import React from 'react';
import StateContext from '../helpers/ContextState';
import NotFound    from '../components/NotFound/BK403';
let render  = false
const usePermissions    =   (prefijo, option, components ) => {
  const context         =   React.useContext(StateContext);
  render=<components.component 
                href={components.href}
                customAdd={components.customAdd} 
                upload={components.upload} 
                download={components.download} 
                skipSearch={components.skipSearch} 
                subFixed={components.subFixed} 
                td={components.td} 
                create={components.create}
        />

  return  {
            open:true,
            render:()=>{return render}
          }
};

export default usePermissions;
